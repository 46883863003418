import React from 'react';
import '../App.css'

export function Equation(props) {

    return (
        <div>
            <p style = {{textAlign:"center", fontFamily:"serif", fontWeight: "600"}}>{props.text}</p>

        </div>
            
            

        );

}
